














import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({ name: 'UserProfilePersonalInformationTabItem' })
export default class UserProfilePersonalInformationTabItem extends Vue {
  @Prop({ type: String, required: true })
  private readonly label!: string;

  @Prop({ type: String, required: true })
  private readonly value!: string;

  @Prop({ type: Boolean, required: false, default: false })
  private readonly loading!: boolean;
}
