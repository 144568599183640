







































































import { Vue, Component } from 'vue-property-decorator';
import UserProfilePersonalInformationTabViewModel
  from '@/vue-app/view-models/components/user-profile/user-profile-tabs/user-profile-personal-information-tab/user-profile-personal-information-tab-view-model';
import UserProfilePersonalInformationTabItem from './user-profile-personal-information-tab-item.vue';

@Component({
  name: 'UserProfilePersonalInformationTab',
  components: { UserProfilePersonalInformationTabItem },
})
export default class UserProfilePersonalInformationTab extends Vue {
  user_profile_personal_information_tab_view_model = Vue.observable(
    new UserProfilePersonalInformationTabViewModel(),
  );

  created() {
    this.user_profile_personal_information_tab_view_model.initialize();
  }
}
