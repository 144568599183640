var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"px-md-10"},[_c('user-profile-personal-information-tab-item',{attrs:{"label":_vm.user_profile_personal_information_tab_view_model.translate('phone'),"value":_vm.user_profile_personal_information_tab_view_model.loading
        ? ''
        : _vm.user_profile_personal_information_tab_view_model.customer_phone,"loading":_vm.user_profile_personal_information_tab_view_model.loading}}),_c('user-profile-personal-information-tab-item',{attrs:{"label":_vm.user_profile_personal_information_tab_view_model.translate('email'),"value":_vm.user_profile_personal_information_tab_view_model.loading
        ? ''
        : _vm.user_profile_personal_information_tab_view_model.customer_personal_information.email,"loading":_vm.user_profile_personal_information_tab_view_model.loading}}),_c('user-profile-personal-information-tab-item',{attrs:{"label":_vm.user_profile_personal_information_tab_view_model.translate('address'),"value":_vm.user_profile_personal_information_tab_view_model.loading
        ? ''
        : _vm.user_profile_personal_information_tab_view_model.customer_personal_information.address,"loading":_vm.user_profile_personal_information_tab_view_model.loading}}),_c('user-profile-personal-information-tab-item',{attrs:{"label":_vm.user_profile_personal_information_tab_view_model.translate('gender.label'),"value":_vm.user_profile_personal_information_tab_view_model.loading
        ? ''
        : _vm.user_profile_personal_information_tab_view_model.translate(
          ("gender.values." + (_vm.user_profile_personal_information_tab_view_model
              .customer_personal_information
              .gender
              .toLowerCase()))
        ),"loading":_vm.user_profile_personal_information_tab_view_model.loading}}),_c('user-profile-personal-information-tab-item',{attrs:{"label":_vm.user_profile_personal_information_tab_view_model.translate('date_of_birth'),"value":_vm.user_profile_personal_information_tab_view_model.loading
        ? ''
        : _vm.user_profile_personal_information_tab_view_model
            .customer_personal_information
            .date_of_birth,"loading":_vm.user_profile_personal_information_tab_view_model.loading}}),_c('user-profile-personal-information-tab-item',{attrs:{"label":_vm.user_profile_personal_information_tab_view_model
    .translate('preferred_means_of_contact'),"value":_vm.user_profile_personal_information_tab_view_model.loading
        ? ''
        : _vm.user_profile_personal_information_tab_view_model
            .customer_personal_information
            .preferred_means_of_contact,"loading":_vm.user_profile_personal_information_tab_view_model.loading}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }