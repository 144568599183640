import TYPES from '@/types';

// Domain
import { GetCustomerPersonalInformationQuery }
  from '@/modules/customer/personal-information/application/queries';
import { PersonalInformation } from '@/modules/customer/personal-information/domain/dtos';
import Inject from '@/modules/shared/domain/di/inject';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';

export default class UserProfilePersonalInformationTabViewModel {
  @Inject(TYPES.GET_CUSTOMER_PERSONAL_INFORMATION_QUERY)
  private readonly get_customer_personal_information_query!: GetCustomerPersonalInformationQuery;

  @Inject(TYPES.I18N)
  readonly translator!: Translator;

  private readonly i18n_namespace = 'components.user-profile.user-profile-tabs.user-profile-personal-information-tab';

  customer_personal_information?: PersonalInformation;

  loading = false;

  customer_phone = ''

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  loadCustomerPhone = (personal_information: PersonalInformation) => {
    if (personal_information.cellphone) {
      this.customer_phone = `${personal_information.cellphone_country_code} ${personal_information.cellphone}`;
    }
    if (!personal_information.cellphone && personal_information.phone) {
      this.customer_phone = `${personal_information.phone_country_code} ${personal_information.phone}`;
    }
  }

  initialize = async () => {
    this.loading = true;
    this.customer_personal_information = await (
      this.get_customer_personal_information_query.execute()
    );
    this.loadCustomerPhone(this.customer_personal_information);
    this.loading = false;
  }
}
